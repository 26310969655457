<template>
<v-app>
  <v-container fluid>
    <v-row>
      <v-col lg="auto" md="auto" sm="auto" xs="auto">
        <template>
          <v-card class="mt-16">
            <!-- expand-on-hover -->
            <v-navigation-drawer class="" permanent :mini-variant.sync="mini" mini-variant-width="75" :width="400">
              <v-list-item class="px-2 accent">

                <v-btn icon @click="changeIcon" @click.stop="mini = !mini">
                  <v-icon>{{ drawerIcon }}</v-icon>
                </v-btn>

                <v-list-item-title>Specify Criteria</v-list-item-title>

                <v-btn icon @click="search">
                  <v-icon>mdi-content-save-alert-outline</v-icon>
                </v-btn>

              </v-list-item>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item v-for="item in queries" :key="item.title" link>
                  <v-list-item-content style="text-align: left;">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>

                  <!-- If Age or BMI: set range slider -->
                  <v-btn v-if="item.title === 'BMI'" class="mr-3" x-small @click="item.disabled = !item.disabled">if available:</v-btn>
                  <v-range-slider :disabled="item.disabled" v-if="item.title === 'Age' || item.title === 'BMI'" v-model="item.range" :max="item.max" :min="item.min" thumb-label="always" thumb-color="blue lighten-2" thumb-size="16" height="10" class="align-center mt-5 mb-3">
                    <template v-slot:prepend>
                      <v-text-field :value="item.range[0]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 45px;" @change="$set(item.range, 0, $event)"></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field :value="item.range[1]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 45px" @change="$set(item.range, 1, $event)"></v-text-field>
                    </template>
                  </v-range-slider>

                  <!-- If anything else: set checkboxes -->
                  <template v-else>
                    <v-checkbox class="ma-0 mt-2 pa-0" v-model="item.model" :label="item.label1" :value="item.value1"></v-checkbox>
                    <v-checkbox class="ma-0 mt-2 pa-0" v-model="item.model" :label="item.label2" :value="item.value2"></v-checkbox>
                  </template>

                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </template>
      </v-col>

      <v-col lg="8" md="7" sm="6" xs="12" class="mx-auto mt-9">

        <div v-if="!loadingData">
          <h3>
            Summary
          </h3>

          <template>
            <v-data-table :headers="headersParticipants" :items="participants_summary" :items-per-age="1" :hide-default-footer="true" class="elevation-1">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.n_participants}}</td>
                  <td>{{ Math.round(item.mean_age*10)/10 }}</td>
                  <td>{{ Math.round(item.percentage_female*10)/10 }}%</td>
                  <td>{{ item.available_cortisol_timepoints | sortTruncateList(20, "...")}}</td>
                  <td>{{ item.n_menstrual_phase}}</td>
                </tr>
              </template>
            </v-data-table>
          </template>

          <h3 class="mt-16">
            Studies
          </h3>

          <template>
            <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="headersStudies" :items="studies" :items-per-page="10" class="elevation-1">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ truncate(item.doi)}}</td>
                  <!-- <td>{{ item.doi.split(";")[0]?item.doi.split(";")[0]:item.doi}}</td> -->
                  <td>{{ item.sample_size_study}}</td>
                  <td>{{ item.sample_size_available}}</td>
                  <td>{{ item.acute_stress_test}}</td>
                  <td>{{ item.time_of_day}}</td>
                  <td>{{ item.questionnaires_tests}}</td>
                </tr>
              </template>
            </v-data-table>
          </template>

          <v-row class="mt-5">
            <v-col>
              <v-btn class="ma-2" :loading="loading" :disabled="loading" color="primary" @click="contactPIs">
                Request Analysis Plan
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="text-center text-justify">
                For further info on how to proceed your data request after downloading the analysis plan and meta-data table by clicking the button above, navigate to our "Request Data" <router-link to="./request_data">page</router-link>.
              </p>
            </v-col>
          </v-row>

        </div>
        <div v-else>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

      </v-col>
    </v-row>
  </v-container>
</v-app>
</template>

<script>
import {
  mapActions,
  mapState
} from "vuex";

import axios from "axios";
import createReport from 'docx-templates';

export default {
  data() {
    return {
      loading: false,
      drawer: true,
      mini: true,
      drawerIcon: 'mdi-chevron-right',
      query: {},
      sortBy: "sample_size_study",
      sortDesc: true,
      queries: [{
          title: "Age",
          min: 0,
          max: 100,
          range: [0, 100],
          disabled: false
        },
        {
          title: "Gender",
          model: ["male", "female"],
          label1: "Male",
          label2: "Female",
          value1: "male",
          value2: "female"
        },
        {
          title: "BMI",
          min: 10,
          max: 50,
          range: [10, 50],
          disabled: true
        },
        {
          title: "Stress Condition",
          model: ["stress", "control"],
          label1: "Stress",
          label2: "Control",
          value1: "stress",
          value2: "control"
        },
        {
          title: "Diagnosis",
          model: ["healthy", "disease"],
          label1: "Healthy",
          label2: "Disease",
          value1: "healthy",
          value2: "disease"
        },
        {
          title: "Contraceptive",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Cortisol Measures",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Amylase Measures",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Any Questionnaires Available",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "VAS Available",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Cognitive Tasks Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Physiological Markers Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Brain Activity Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Genetics Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Transcriptomics Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Epigenetics Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Proteomics Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Metabolomics Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Immune System Measured",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
        {
          title: "Biobank Stored Tisues",
          model: ["yes", "no"],
          label1: "Yes",
          label2: "No",
          value1: "yes",
          value2: "no"
        },
      ],
      headersParticipants: [{
          text: "Participants Satisfying Conditions",
          align: "start",
          sortable: false,
          value: "n_participants",
        },
        {
          text: "Mean Age",
          value: "mean_age"
        },
        {
          text: "Percentage Female",
          value: "percentage_female"
        },
        {
          text: "Cortisol Timepoints Available (min)",
          value: "available_cortisol_timepoints",
        },
        {
          text: "Menstrual Phase Available (n)",
          value: "n_menstrual_phase"
        },
      ],
      headersStudies: [{
          text: "DOI",
          align: 'start',
          sortable: false,
          value: "doi"
        },
        // {
        //   text: "DOI",
        //   value: "doi"
        // },
        {
          text: "Sample Size Study",
          value: "sample_size_study",
        },
        {
          text: "Sample Size Directly Available",
          value: "sample_size_available"
        },
        {
          text: "Acute Stress Test",
          value: "acute_stress_test"
        },
        {
          text: "Time of Day",
          value: "time_of_day"
        },
        {
          text: "Questionnaires",
          value: "questionnaires_tests"
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "studies",
      "participants_summary",
      "loadingData",
    ])
  },
  methods: {
    ...mapActions([
      "queryStudies",
    ]),
    contactPIs() {

      this.makeQuery();

      axios.post("/api/newRequest", this.query)
        .then((doc) => {
          axios.post("/api/downloadStudies", this.studies, {
              responseType: 'blob'
            })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', doc.data.reqId + '.csv');
              document.body.appendChild(link);
              link.click();
            });

          axios.get("/analysis_plan_template.docx", {
              responseType: 'blob'
            })
            .then((response) => {

              createReport({
                template: response.data,
                data: {
                  reqID: doc.data.reqId
                },
              }).then(
                rendered => {
                  const url = window.URL.createObjectURL(new Blob([rendered]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', doc.data.reqId + '.docx');
                  document.body.appendChild(link);
                  link.click();
                });
            });
        });
    },
    changeIcon() {
      if (this.drawerIcon === 'mdi-chevron-right') {
        this.drawerIcon = 'mdi-chevron-left';
      } else {
        this.drawerIcon = 'mdi-chevron-right';
      }
    },
    makeQuery() {
      const age_years = this.queries[0].range; //participant
      const gender = this.queries[1].model; //participant
      const bmi = this.queries[2].range; //participant
      const stress_control_condition = this.queries[3].model; //participant
      const diagnosis = this.queries[4].model; //participant
      const contraceptive = this.queries[5].model; //participant
      const cortisol_measured = this.queries[6].model; //cortisol
      const alphaamylase_measured = this.queries[7].model; //alphaamylase
      const questionnaires_measured = this.queries[8].model; //studies
      const questionnaires_vas_yes_no = this.queries[9].model; //vas available 
      const cognitive_tasks_measured = this.queries[10].model; //cognitive tasks measured 
      const physiology_measured = this.queries[11].model; //physiology measured
      const brain_activity_measured = this.queries[12].model; //brain activity measured
      const genetics_measured = this.queries[13].model; //genetics measured
      const transcriptomics_measured = this.queries[14].model; //transcriptomics measured
      const epigenetics_measured = this.queries[15].model; //epigenitcs measured
      const proteomics_measured = this.queries[16].model; //proteomics measured
      const metabolomics_measured = this.queries[17].model; //metabolomics measured
      const immunesystem_measured = this.queries[18].model; //immune system measured
      const biobank_stored = this.queries[19].model; //immune system measured

      this.query = {
        query: {
          participants: {
            age_years: age_years,
            gender: gender,
            bmi: bmi,
            stress_control_condition: stress_control_condition,
            diagnosis: diagnosis,
            contraceptive: contraceptive,
          },
          studies: {
            cortisol_measured: cortisol_measured,
            alphaamylase_measured: alphaamylase_measured,
            questionnaires_measured: questionnaires_measured,
            questionnaires_vas_yes_no: questionnaires_vas_yes_no,
            cognitive_tasks_measured: cognitive_tasks_measured,
            physiology_measured: physiology_measured,
            brain_activity_measured: brain_activity_measured,
            genetics_measured: genetics_measured,
            transcriptomics_measured: transcriptomics_measured,
            epigenetics_measured: epigenetics_measured,
            proteomics_measured: proteomics_measured,
            metabolomics_measured: metabolomics_measured,
            immunesystem_measured: immunesystem_measured,
            biobank_stored: biobank_stored,
          }
        }
      }

    },
    search() {
      this.makeQuery();
      this.queryStudies(this.query);
    },
    truncate(text, limit = 20, suffix = '...') {
            if (text.length > limit) {
                return text.substring(0, limit) + suffix;
            } else {
                return text;
            }
    }
  },
  mounted() {
    this.queryStudies(this.query);
  },
};
</script>
